
.cartcontainer{
width: 90%;
margin: 0 auto;
margin-top:100px;
margin-bottom: 200px;
max-width: 1200px;
}

.Carthead{
width:100%;
display: flex;
text-align: left;
padding: 10px;
background-color: rgb(236, 234, 234);
}

.cartmaininfo{
    display: flex;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgb(209, 207, 207);
}
.sit{
    width:40%;   
}

.at {
    width: 20%;
 

}
.pt {
    width: 20%;

}


.qt {
    width: 20%;

}

.picndinfo{
    width: 40%;
    display: flex;
}

.qndp{
    width:60%;
    display: flex;
}

.mainprice{
        width: 33.3%;
        font-weight: bold;
            font-size: 22px;
            padding: 10px;
}
.mainquantity{
    width:33.3%;
    display: flex;
    padding: 10px;
    
   
}

.actcont {
    width:33.3%;
    color: red;
    display: flex;
    align-items: center;
    padding: 10px;
}

.cartiteminfo{
width:75%;
padding: 5px;
}

.itempic{
width:25%;
}

.sertitle{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
}

.serinfo{
    color: rgb(3, 89, 3);
    font-size: 15px;
}

.addcopy{
    width:35px;
    height: 25px;
    box-shadow: rgba(10, 10, 19, 0.5) 0px 1px 3px;
        display: flex;    justify-content: center;
    align-items: center;
    transition: 0.5s;
}
.copy{
    width: 35px;
        height: 25px;
        display: flex;
            justify-content: center;
                align-items: center;
}
.remcopy {
    width: 35px;
        height: 25px;
    box-shadow: rgba(10, 10, 19, 0.5) 0px 1px 3px;            display: flex;
        justify-content: center;
            align-items: center;
                transition: 0.5s;
}

.remcopy:hover {
background-color: black;
    color: #00ff00;}
.addcopy:hover {
    background-color: black;
    color: #00ff00;
}

.cart_submit {
    background-color: black;
    color: #00ff00;
    padding: 13px;
    border: none;
    font-size: 14px;
    width: 350px;
}

.cart_submitcont {
    margin-top: 20px;
    width:100%;
    display: flex;
    justify-content: right;
    background-color: white;
    
    
}

.callws{
    padding: 8px;
        border: 2px solid black;
        border-radius: 5px;
        margin-left: 5px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
}

.paymeanstxt{
    text-align: center;
    padding: 20px;
    font-size: 14px;
    color: rgb(1, 48, 1);
}

.bksummary{
    display: flex;
    justify-content: space-between;
}
.bk{
    padding: 10px;
    display: flex;
    border-bottom: 1px solid rgb(224, 223, 223);
    font-weight: bold;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.bkbtn{
    display: flex;
align-items: center;
font-size: 14px;
color: red;
margin-left: 15px;
}

.summary{
    display: flex;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid rgb(224, 223, 223);
        
}

.sumprice{
    font-size: 18px;
    margin-left: 15px;
}


@media screen and (max-width: 767px) {
.cartcontainer {
        width: 100%;
    }
        .Carthead {
            display:none;
        }
.cartmaininfo{
    display: block;
}
.picndinfo{
            width:100%;
        }
 .itempic{
    width:35%;
 }

        .qndp{
        width: 100%;
        }

.mainquantity{
    width:30%;
}
  
.actcont{
   float: right;
  
 }

 .actcont{
        text-align: center;
        font-size: 14px;
       text-decoration:underline;
 }
.serinfo{
    font-size: 14px;
}

.cartmaininfo{
    padding: 3px;
    padding-right: 8px;
}

.cart_submit {
  
}

.cart_submitcont {
position: fixed;
bottom: 0;
padding: 5px;
justify-content: center;
box-shadow: rgba(10, 10, 19, 0.5) 0px 1px 7px;
margin-bottom: 0;

    }

.bksummary {
    display: block;
}
.summary{
    background-color: rgb(224, 223, 223);
}

}