*{
  margin:0;
  padding:0;
  
}

li{
  list-style-type: none;
}
.profile_submit {
  float: right;
  margin-top: 5px;
  color: white;
  background-color: blue;
  border: none;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  width: 30%;
  margin-right: 15px;
}
.profile_submit:hover {
  box-shadow: 0 1px 1px 1px grey;
}

.profile_submit:active {
  background-color: rgb(8, 8, 128);
}
.view_pic_container {
  background-color: rgb(49, 40, 175);
  padding-top: 20px;
  height: 180px;
  padding: 10px;
}
.pware_profile {
  font-size: 20px;
  color: white;
  display: flex;
  justify-content: center;
  padding: 6px;
}
.view_pic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 15px;
}
.view_name_text {
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.view_balance {
  display: flex;
  justify-content: space-between;
  color: white;
  /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
  font-size: 15.5px;
  font-weight: 600;
  width: 100%;
}
.view_balance_text {
  display: flex;
}
.view_balance_amount {
  margin-left: 7px;
  font-size: 23px;
}
.add {
  background-color: thistle;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.others {
  padding: 15px;
}
.other_details {
  margin-top: 8px;
}

.other_details_title {
  font-weight: 600;
}
.other_details_content {
  font-weight: 500;
  background-color: rgb(250, 245, 245);
  padding: 10px;
  border-radius: 5px;
}

.view_head {
  margin-left: 15px;
}

.pay_container {
  display: flex;
  justify-content: center;
}

.pay_card {
  width: 350px;
  background-image: linear-gradient(45deg, #0aa30a, #118827, #0c4717);
 
  border-radius: 10px;
  box-shadow: 2px 3px 0 rgb(0 0 0 / 20%) !important;
  
  padding: 20px;
}
.wallet_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.addtowallet {
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  padding-top: 8px;
  padding-left: 7px;
  color: black;
}

.wallet {
  background-color: black;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  padding-top: 8px;
  padding-left: 7px;
  color: white;
}

.Amount {
  background-color: transparent;
  display: flex;
  margin-top: 50px;
  font-size: 25px;
  color: white;
}

.currency {
  margin-right: 5px;
}

.card_title {
  margin-top: 5px;
  color: white;
  font-size: 14px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.issuer {
  margin-top: 5px;
  float: right;
  color: white;
  font-size: 9px;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.profile_first {
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-bottom: 7rem;
  border: 1px solid #d9dadb;
  box-shadow: 0 13px 0 rgba(82, 63, 105, 0.05);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  max-width: 750px;
  max-height: 100%;
  overflow: auto;
}

.profile_second {
  margin-left: 10px;
  color: black;
  float: left;
  border: 1px solid #d9dadb;
  box-shadow: 0 13px 0 rgba(82, 63, 105, 0.05);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  width: 48%;
}

.instruction {
  display: block;
}
.instruction_text {
  background-color: #87f587;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  padding: 5px;
  font-weight: bold;
}


.instruction_title {
  margin-top: 40px;
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
}

.instruction_subtitle {
  margin-top: 20px;
 
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.instruction_subtitled {
  background-color: black;
  width: 130px;
  color: white;
  padding-left: 5px;
  padding-top: 4px;
  border-radius: 5px;
  display: flex;
}

.instruction_icon {
  margin-left: 10px;
}

.error_reply{
font-size: 14px;
color: red;
padding-bottom: 8px;
}

.visible_button {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  height: 30px;
  border-radius: 10px;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
}

.visible_button:hover {
  box-shadow: 0 1px 1px 1px grey;
}

.browse_button {
  position: relative;
  top: -25px;
}

.reciept {
  width: 100%;
  height: 250px;
  background-color: rgb(231, 229, 229);
  margin-bottom: 10px;
  color: black;
  font-weight: 600;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profile {
  margin: 0 auto;
  max-width: 450px;
  margin-top: 9rem;
  border: 1px solid #d9dadb;
  overflow: auto;
}

.main_upload {
  display: none;
}

.wall_submit {
  background-color: black;
  float: right;
  width: 100px;
  padding: 8px;
  border: none;
  color: #00ff00;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 50px;
}

.edit_submit{
  background-color: black;
    float: right;
    width: 100px;
    padding: 8px;
    border: none;
    color: #00ff00;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.mainlogo{
  height:60px;
  border-radius:50%;
}
.l_cont{
  width: 100%;
  display: flex;
  justify-content: center;
}


/*......................DashBoard...........................*/
.dash_head{
  padding: 20px;
  font-size: 17px;
  font-weight: 600;
  color: green;
}
.dash_cont {
 background-color:black;
 color: white;
  padding-top:20px;
min-height: 100vh;
}



.show_cont{
  display: none;
}


.width_incre{
  width:100% !important;
  position: fixed !important;
  left: 0 !important;
}

.profile_menu:hover{
background-color: #00ff00;
color: black;
border-radius: 25px 0px 0px 25px ;
}
.profile_menu2:hover {
  background-color: #00ff00;
  color: black;
  border-radius: 25px 0px 0px 25px;
}
.pro{
  margin-top:5px;
}

.pro_head{
  color: black;
  background-color: rgb(233, 230, 230);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pro_subhead {
padding: 15px;
}
.pro_subhead:hover{
  background-color: #00ff00;

}

.dash_content{
  max-width:700px;
  padding-top: 87px;
}

.dash_content2 {
  max-width: 780px;
  padding-top: 87px;
}
/*.profile_menu p{
  display: none;
}

.profile_menu2 p {
  display: none;
}*/
.profile_menu{
  font-size: 14px;
  padding: 15px;
  padding-left: 20px;
  display: flex;
  align-content:center;
}

.profile_menu2{
  font-size: 14px;
  padding: 15px;
  padding-left: 20px;
  border-bottom: 2px solid #00ff00;
  display: flex;
}

.iconpro{
  margin-right: 25px;
}

.newside{
  width:210px;
  float: left;
  min-height: 100vh;
  position:fixed;
  
}
.main_dash{
  width:calc(100% - 210px);
  float: left;
  position: fixed;
  left:210px;
}

.dash_container{
  width:100%;
  overflow: auto;
  position: fixed;
}

.content{
  position:relative;
}

.welcome_profile{
  margin-left: 240px;
  padding-top: 50px;
  overflow: auto;
}

.E_input{
  width: 400px;
padding: 8px;
padding-left: 12px;
margin-bottom: 25px;
border-radius: 4px;
outline: none;
border: none;
border: 1px groove #babbbe;
color: rgb(36, 35, 35);
font-size: 13px;
background-color: white;
font-weight: 500;
text-align: left;
margin-right:15px;
margin-top:10px;
}

.radioans{
  font-family:"Roboto",
  "Helvetica",
  "Arial",
  sans-serif;
  font-size: 16px;

}
.radioans p{
  width:400px;
}


.E_q{
  color:green;
}


.pro_subhead2 {
  padding-right: 20px;
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
}

.profile_title{
  font-size:18px;
  font-weight: bold;
}

body{
  font-family:"Roboto",
  "Helvetica",
  "Arial",
  sans-serif;
}

.title{
  font-size: 15px;
  font-weight: bold;
  color: #035c03;
  margin-bottom: 25px;
  border-bottom: 1px solid #eaeaeb;
  text-align: left;
  padding-bottom:7px;
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
}

.edit_plus{
width:40%;
float:left;
color:blue;
font-size: 14px;
}
.edit{
  float:left;
  width:150px;
   background-color: black;
   color: white;
   padding-bottom: 5px;
  padding-left: 5px;
  cursor: pointer;
    border-radius: 5px;
}
.edit2 {
  float: left;
  width: 150px;
  background-color: black;
  border-radius: 7px;
  color: white;
  padding: 8px;
  padding-left: 5px;
}
.edit_details {
  float: left;
  width: 150px;
  background-color: black;
  color: white;
  padding-bottom: 5px;
  padding-left: 5px;
}

.editpro:hover{
background-color: #43fa43;
}


.info_holder {
  margin-left: 5%;
  width: 55%;
  float: left;
  font-size: 15px;
}

.p_pic{
  width:150px;
  height:150px;
  border: 2px solid #00ff00;
  overflow: hidden;
}

.u_pic{
  width:190px;
  height:150px;

}
.pic_holder{
  margin-bottom: 15px;
}

.info_name{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
  padding-top: 15px;
}
.info_named {
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
}
.info_title{
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(223, 223, 223);
  padding-bottom: 6px;
}

.info_subtitle{
padding-top: 35px;
}

.info_subtitle li {
  padding-top: 15px;
}
.info_subtitle p {
  padding-bottom: 10px;
  font-weight: bold;
}




.dash_content h3{
  margin-top:60px;
    margin-bottom:30px;
}

.trans{
width:180px;
padding: 10px;
background-color: black;
color: white;
border-radius: 10px;
margin-bottom: 15px;
}

.back{
width:180px;
padding: 10px;
background-color: #00ff00;
color: black;
border-radius: 10px;
display: flex;
justify-content: center;
}

.btn_trans{
  margin-top: 35px;
}
@media screen and (max-width: 767px) {
  .instruction_title {
    font-size: 15px;
  }
  .profile_first {
    margin-bottom: 0px;
    width:98%;
  }

  .pay_card {
    width: 300px;
    height: 160px;
  }
  .addtowallet {
    width: 30px;
    height: 30px;
    padding-top: 3px;
  }
  .wallet {
    width: 30px;
    height: 30px;
    padding-top: 3px;
  }
  .Amount {
    display: flex;
    margin-top: 30px;
    font-size: 20px;
  }
  .instruction_subtitle {
    font-size: 14.5px;
  }
  .instruction_title {
    font-size: 16px;
  }


 
  .dash_content p {
    font-size: 14px;
  }
 
 

.width_incre{
width: calc(100% - 210px) !important;
position: fixed !important;
left:210px !important;
}

.welcome_profile {
  margin-left: 0;
  padding: 20px;
  padding-top: 50px;
}


}

/*ttt*/
* {
  margin: 0;
  padding: 0;
}

.pdf-container {
  width: 100%;
  height: 800px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-msg {
  width: 100%;
  color: red;
  font-size: 14px;
  font-weight: 600;
}

.edit_input{
 border:none;
 border-bottom: 1px solid rgb(74, 74, 74); 
 width:100%;
 padding:5px;
 margin-top: 10px;
 font-size: 14px;
 background-color: rgb(249, 245, 245);
 max-width: 380px;
}

@media screen and (max-width: 600px) {
  .profile_second {
    margin-top: 5px;
    width: 98%;
    padding: 5px;
  }
  .profile {
    margin: 0 auto;
    margin-top: 9rem;
    max-width: 100%;
  }

 
.E_input {
  width:100%;
  margin-right: 0;
}


.radioans p {
  width: 100%;
}

}
@media screen and ( min-width: 1200px){

.dash_content{
  margin:0 auto;
  padding-top:90px;
}
.dash_content2 {
  margin: 0 auto;
  padding-top: 90px;
}

.pic_holder{
  display: flex;
  justify-content: left;
}

}

@media screen and (max-width: 1200px) {
  .E_input{
    /*width: 45%;*/
  }

  .newside {
    display: none;
  }

  .main_dash {
    width: 100%;
    position: fixed;
    left: 0;
  }

  .dash_content{
    width:100%;
    margin: 0 auto;
    padding-left:5%;
    padding-right:5%;
    
  }
.dash_content2 {
  width: 100%;
  margin: 0 auto;
 

}
  .show_cont {
    display: block;
  }


  
  .edit_plus {
width:100%;
text-align: center;


  }
  .info_holder {
    width: 100%;
    margin-left: 0%;
  }

  .pic_holder {
    display: flex;
    justify-content: center;
  }

  .width_incre{
    width:calc(100% - 210px) !important;
    position:fixed;
    left:210px !important;
  }
}

