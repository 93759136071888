.menuTop{
    background-color: #f6f9fc;
    width: auto;
    height:390px;
    padding-left: 2.5%;
    margin-top: 80px;
}

.product_menu {
    width: 210px;
  float: left;
margin: 15px;
background-color: white;   

}


.menu_item {
 
    padding-top: 20px;


}

.title_cont{
    
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            border-bottom: 1px solid grey;
            padding-bottom: 10px;
        
}

.shop_pro {

    margin-top: 15px;
    border-bottom: 1px solid #00ff00;
}


.itemdisplay{
    
    padding-bottom: 30px;
    float:left;
    padding-left: 2.5%;
    padding-right: 2.5%;
    width: 100%;
    background: #f6f9fc;
}


.availCont{
    width:100%;
}
.avail_item{
    width:19%;
    height: auto;
    float:left;
    padding:20px;
    padding-bottom: 30px;
    cursor: pointer;
    border-radius: 8px;
    margin-left: 1%;
    margin-top: 15px;
    background-color:white;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    max-width: 250px;
}

.item_img{
    width:100%;
    max-width: 240px;
    
    
}


.item_name{
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 5px;
}

.otherinfo{
    font-size: 13px;
    padding-bottom: 5px;
}

.itemamount{
    font-size: 17px;
    font-weight: bold;
    float: left;
    color: red;
}

.discount2{
    position: relative;
        top: 0;
        left: 0;
        background: green;
        padding: 3px 10px;
        font-size: 12px;
        border-radius: 50px;
        color: #fff;
        margin: 10px;
}

/*.pluscart{
font-size: 12px;
display: flex;
padding-top: 5px;
padding-bottom: 5px;
margin-top: 5px;
align-content: center;
background-color: #00ff00;
color: black;
font-weight: bold;
cursor: pointer;
border-radius: 5px;
}

.pluscart:hover {

    background-color: #b7ffb7;
}*/
.pluscart {
background: none;
color: #e94560;
font-size: 20px;
transition: 0.5s;
border: 1px solid rgba(1, 22, 20, 0.09);
width: 35px;
height: 35px;
border-radius: 5px;
float: right;
text-align: center;
}


.alignHolder{
    position: relative;
    bottom: -20px;
}


.iconpro2 {
  margin-right: 25px;
color:rgb(128, 3, 3)}

.extra1_holder{
float: right;
    display: block;
    width:100%;
}

.extra2_holder {
    font-size: 14px;
    float:right;
    display: none;

}

.eithershow{
display: block;
}

.orhide{
    display: none;
}

.advert{
    width:  calc(97.5% - 250px);
    float: left;
 margin-left:2%;
 margin-top: 20px;
    background-color: grey;
    height: 330px;
    
}
.product_menusmall {
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    z-index: 100;
    padding-left: 2.5%;
    padding-right: 2.5%;
color: black; 
font-size:13px;
box-shadow: rgb(3 0 71 / 9%) 0px 1px 5px;
        border-top: 1px solid #00ff00;

}
.downmenuitem{
    width:20%;
    float:left;
    text-align: center;
}


.smallicon{
    display: flex;
    justify-content: center;
    color: red;
}

/*.maindowncat{
padding: 10px;
}

.maindowncat tr, td, th {
    border: none;
}
table{
    width:100%;
}*/
.maindown{
    height: 100%;
    position: relative;
}

.addspace{
    display: block;
}




/*Preview shop item before payment --------for Preinfo.jsx------*/
.preview{
    width:100%;
    margin-top: 85px;
    background-color: #f6f9fc;
    display: flex;
    padding: 40px;
}

.previmg{
    width: 35%;
    background-color: white;
    float: left;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
}
.previmg img{
    width:90%;
}

.previnfo {
    width: 63%;
    margin-left: 2%;
    background-color: white;
    float: left;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    padding: 20px;
}

.tofloat{
    width:100%;
}

.Pname{
    font-size: 35px;
    font-weight: bold;

}

.Pothers {
    font-size: 25px;
    margin-top: 5px;

}

.Price{
    padding-top: 15px;
    font-size: 25px;
    font-weight: bold;
}

.Pquantity{
    margin-top: 15px;
    color: red;
}

.buy {
    font-size: 16px;
    font-weight: bold;
    background-color: #00ff00;
    padding: 15px;
    width: 150px;
    text-align: center;
   position: relative;
   right: 5px;
   border-radius: 5px;
   cursor: pointer;

}

.otheroptions {
    display: flex;
    margin-top: 40px;
    align-items: center;
    max-width: 300px;
}
.othereq{
    padding: 10px;
    border: 2px solid #00ff00;
    color: green;
    border-radius: 5px;
    margin-left: 10px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*............................................................................*/
@media screen and (max-width: 900px) {
.avail_item{
   padding: 10px;
   padding-bottom: 30px;
}}


@media screen and (max-width: 767px) {
    .product_menu{
       display:none;
    }

    .title_cont{
        display: block;
        padding-left: 10px;
    }
    .show_list {
    display: none;
   }

  

 

   .advert {
    width: 100%;
   margin-left: 0%;}



  .menuTop {
      padding-left: 0;
  }

   .itemdisplay {
       width: 100%;
       margin-left: 0;
   }

   .avail_item{
    width:49%
   }

   .downmenuitem:hover {
       cursor: pointer;
border-left: 1px solid rgb(233, 109, 109);
border-right: 1px solid rgb(233, 109, 109);
   }


/*Preview shop item before payment / Mobile view --------for Preinfo.jsx------*/
   .preview {
       width: 100%;
       display: block;
       padding: 20px;
   }

   

   .previmg {
       width: 100%;
       float: none;
       margin-bottom: 20px;
   }

   .previnfo {
       width: 100%;
    float: none;
    margin-left: 0;

  }

  .Pname {
      font-size: 25px;

  }

  .Pothers {
      font-size: 18px;
      

  }

  .Price {
      padding-top: 10px;
      font-size: 20px;
      font-weight: bold;
  }

  .Pquantity {
      margin-top: 10px;
      font-size: 14px;
  }

  
.buy{
    font-size: 14px;
}

}

@media screen and (min-width: 1200px) {
    
}

@media screen and (min-width: 767px) {
    .product_menusmall{
        display:none;
    }

    .addspace{
        display: none;
    }

      
}