* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --heights: 50vh;
  --widths: 100%;
}

.slider-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
  background-color: black;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
  height: 100%;;
}

.slides {
  height: 100%;
  width: var(--widths);
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 40%;
  z-index: 10;
}

.slide-text {
  top: 65%;
  font-size: 2rem;
}

.prev,
.next {
  color: white;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
 background-color: black;
  margin-top: -3rem;
  font-size: 27px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}

/*.slider-container:hover .prev,
.slider-container:hover .next {
  color: black
}*/

.slider-container:hover .prev:hover,
.slider-container:hover .next:hover {
  color: #00ff00;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;

  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin: 0px 3px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 50%;
  display: inline-block;
}

.slider-container:hover .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
} */

.slider-container:hover .dot {
  background-color: rgba(0, 0, 0, 0.3);
}

.slider-container:hover .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
}

.play-pause {
  color: black;
}


.slider-container{
height: 380px;
background-color: black;
  }

  .second_cont{
    background-color:white;
    height:100%;
  padding:20px;
  padding-left: 40px;
  padding-right: 40px;
  background-image: linear-gradient(45deg, rgb(247, 246, 250), rgb(247, 246, 250), rgb(247, 246, 250), rgb(77, 158, 77), rgb(18, 207, 56));
  font-size: 15px;  }

  .fill_cont{
    height:100%;
  }

  .third_cont{
    height:100%;

    color:white;
    padding: 20px;
      padding-left: 40px;
      padding-right: 40px;
      font-size: 15px;
    background-image: linear-gradient(45deg, rgb(4, 96, 158), rgb(45, 6, 186), rgb(83, 2, 83) );
  }

  .text_cont{
float: left;
text-align: center;
width:45%;
padding-top: 40px;

  }

  
  /*..................................*/

.slide_logo{
border-radius: 50%;
height:100px;
float:left;
margin-top: 50px;
}

.large_format{
 color: black;
 font-size: 35px;
}

.wr{
  width:600px;
}

.bigText{
  font-size: 70px;
  color: rgb(4, 59, 4);
  font-weight:bolder;
 
}

.print_now{ width:130px;
  padding: 10px;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
margin-top: 20px;}

  .ph{
    width:100%;
    display: flex;
    justify-content: center;
  }

    .text_cont2{
      float: right;
      text-align: right;
      padding-top: 40px;
    }

         .ph2 {
           width: 100%;
           display: flex;
           justify-content: right;
         }

                 .bigText2 {
                  font-size: 60px;
                    color: white;
                    font-weight: bolder;
                 }

  .graphics_d{
    color: white;
  }

  .imggraph{
    width:500px;
    float: left;
  }

  .dont{
    display: none;
  }
@media screen and (max-width: 767px) {
 
  .slider-container{

  }

  .text_cont{
width:100%;

  }

      
}

@media screen and (max-width:850px) {
    .imggraph {
  display: none;
      }
}
