.realign{
    display: flex;
   justify-content: right;
}

.soontext {
   margin-top: 200px;
    margin-bottom: 100px;
    font-size: 20px;
    color: rgb(4, 59, 4);
    font-weight: bolder;
    text-align: center;
}

.toleft{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.contac:hover{
    transition: 0.5s;
    border-bottom: 2px solid green;
    color: #076007;

}

.repair{
    font-size: 35px;
}