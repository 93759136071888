.paypass{
    width:350px;
    margin: 0 auto;
    text-align: center;
    text-decoration: wavy;

}

.payins{
        text-align: center;
        
}

.payerr{
    color: red;
    font-size: 14px;
}

.pay_submit {
    background-color: black;
    color: #00ff00;
    padding: 13px;
    border: none;
    font-size: 14px;
    width: 350px;
   
    text-align: center;
}



/*..................Confirm payment..................*/

.confirmcont{
 background-color: #f6f9fc;
 width: 100%;
 height:100vh;
}

.print_cont {
    margin: 0 auto;
    margin-top: 4rem;
    border: 1px solid #d9dadb;
    box-shadow: 0 13px 0 rgba(82, 63, 105, 0.05);
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
    max-width: 400px;
    max-height: 100%;
    overflow: auto;
}

.print_title {
    font-size: 20px;
    color: green;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
}

.tick {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-image: linear-gradient(45deg, green, rgb(5, 184, 5), #00ff00);
    color: white;
    width:50px;
    height:50px;
    margin: 0 auto;
        margin-bottom: 8px;
    /*color: green;
    margin-bottom: 15px;*/
}

.pay_details {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
}

.p_img {
    height: 100%;
    width: 100%;
}


@media screen and (max-width: 767px) {
.pay_submit {
margin: 0 auto;
}


/*..............confirm...........................*/

 .print_cont {
     width: 90%;
 }

 .print_title {
     font-size: 19px;
 }

 .print_details {
     font-size: 14px;
 }
}