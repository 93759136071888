.comment {
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  color: rgb(31, 1, 141);
  font-family: cursive;
}

.sign_in {
  border: 2px solid rgb(31, 1, 141);
  border-radius: 5px;
  padding-bottom: 8px;
  padding-top: 8px;
  margin-bottom: 20px;
  width: 370px;
  height: 40px;
  padding-left: 30px;
  color: rgb(31, 1, 141);
  font-weight: bold;
}

.submit2 {
  background-color: rgb(31, 1, 141);
  color: white;
  display: flex;
  padding: 10px;
  justify-content: center;
  text-decoration: none;
  border-radius: 5px;
}

#terms {
  width: 20px;
}

.sigin_input {
  width: 100%;
  padding: 8px;
  padding-left: 12px;
  margin-bottom: 25px;
  border-radius: 4px;
  border: none;
  border: 1px groove #babbbe;
  color: rgb(36, 35, 35);
  font-size: 13px;
  background-color: white;
  font-weight: 500;
}
