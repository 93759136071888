.login_card {
  display: flex;
  flex-direction: column;
}
.pware {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color:black;
  margin-bottom: 20px;
}
.login_container {
  color: rgb(36, 35, 35);
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  margin-top: 11rem;
  margin-bottom: 7rem;
  border: 1px solid #d9dadb;
  box-shadow: 0 13px 0 rgba(82, 63, 105, 0.05);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  max-width: 450px;
}

#fname {
  width: 100%;
  padding: 8px;
  padding-left: 12px;
  margin-bottom: 25px;
  border-radius: 4px;
  outline: none;
  border: none;
  border: 1px groove #babbbe;
  color: rgb(36, 35, 35);
  font-size: 13px;
  background-color: white;
  font-weight: 500;
  text-align: left;
}

#fname::placeholder {
  color: rgb(36, 35, 35);
}
#fname:focus,
#pass:focus {
  outline: 1px groove rgb(31, 1, 141);
}
#pass {
  width: 100%;
  padding: 8px;
  padding-left: 12px;
  margin-bottom: 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  border: 1px groove #babbbe;
  color: rgb(36, 35, 35);
  font-size: 13px;
  background-color: white;
  font-weight: 500;
}

.user-img {
}

label {
}

.submit {
  width: 100%;
  padding: 8px;
  padding-left: 12px;
  margin-bottom: 25px;
  border-radius: 4px;
  outline: none;
  border: none;
  color: black;
  font-size: 13px;
  background-color: #00ff00;
  font-weight: bold;
}

.submit a {
  color: white;
}

.check {
  float: left;
  width: auto;
  cursor: pointer;
  color: white;
}

.forgot {
  width: auto;
  float: right;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}
.forgot:hover {
  border-bottom: 1px groove purple;
}
.create {
  text-align: center;

  font-size: 13.5px;
  font-family: "Open Sans", sans-serif;
}

.create_account {
color: rgb(17, 134, 20) !important;}
.create_account:hover {
  color: rgb(168, 6, 168);
}

.recover_instruct {
  font-size: 12px;
  margin-bottom: 10px;
  color: grey;
}
/*.........................................................................................................*/
@media screen and (max-width: 768px) {
  .login_container {
    width: 95%;
    margin-top: 90px;
  }
}
