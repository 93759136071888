/* for profile and payment */
* {
  box-sizing: border-box;
}

.form_intro {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.intro_cont {
  width: 60%;
}

.form_container {
  background-color: white;
  width: 48%;
  float: left;
}

.upload_container {
  margin-left: 3%;
  width: 49%;
  float: left;
  min-height: 250px;
}

.upload_container p {
  font-size: 15px;
  padding-bottom: 10px;
}

.form_input {
  margin-bottom: 20px;
  width: 100%;
  height: 36px;
  border: none;
  background-color: rgb(231, 228, 228);
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}

.describe_input {
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  border: none;
  background-color: rgb(231, 228, 228);
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.form_submit {
  background-color: black;
  float: right;
  width: 100px;
  padding: 10px;
  border: none;
  color: white;
  font-weight: 600;
  border-radius: 5px;
}

.img_preview {
  text-align: center;
  height: 220px;
  padding-top: 50px;
  background-color: rgb(252, 247, 247);
  margin-bottom: 10px;
  overflow: hidden;
}

.s_info {
  font-size: 12px;
  color: rgb(206, 10, 10);
  background-color: rgb(234, 234, 234);
  margin-bottom: 15px;
  padding: 8px;
}

.other_ft {
  display: flex;
  align-items: baseline;
  padding-bottom: 7px;
  border-bottom: 2px solid rgb(171, 168, 168);
}

.import {
  width: 100%;
  height: 35px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00ff00;
  background-color: black;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

.s_submit {
  background-color: black;
  float: right;

  padding: 8px;
  border: none;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 40px;
}

.import1 {
  display: none;
}

.transact_title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  color: rgb(64, 25, 204);
  font-weight: 600;
  margin-bottom: 25px;
  padding-left: 15px;
}

/*.next {
  float: right;
  width: 80px;
  border-radius: 2px;
  background-color: rgb(64, 25, 204);
  color: white;
  margin-right: 20px;
  text-decoration: none;
  padding: 4px;
}*/

.copy_input {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  width: 80px;
  height: 36px;
  border: none;
  background-color: rgb(231, 228, 228);
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}

.size_input {
  margin-bottom: 20px;
  margin-left: 30px;
  width: 80px;
  height: 36px;
  border: none;
  background-color: rgb(231, 228, 228);
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
  font-size: 14px;
}

.size_input {
  margin-bottom: 20px;
  margin-right: 15px;
  width: 60px;
  height: 36px;
  border: none;
  background-color: rgb(231, 228, 228);
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
  font-size: 14px;

}

.size_input2 {
  margin-bottom: 20px;
  margin-left: 10px;
  width: 60px;
  height: 36px;
  border: none;
  background-color: rgb(231, 228, 228);
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
  font-size: 14px;
}

.multi_upload {
  width: 30%;
  height: 60px;
  margin-left: 3.3%;
  float: left;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid grey;
}

.transact_cart {
  width: 270px;
  position: fixed;
  top: 90px;
  right: 10px;
  background-color: white;
  border: 2px solid #d9dadb;
  box-shadow: 0 13px 0 rgba(82, 63, 105, 0.05);
}

.p_component {
  display: block;
}

.p_hide {
  display: none;
}

.others_show {
  display: block;

}

.others_hide {
  display: none;

}

/*.general_form {
  margin: 0 auto;
  width: 820px;
}*/

/*Payment css--------------------------------------------------------------------------- */
.pay_picture {
  float: left;
  width: 40%;
  margin-bottom: 15px;
}

.pay_contain {
  display: flex;
  flex-direction: column;
  float: left;
  width: 59%;
}

.pay-content {
  padding: 10px;
  border-bottom: 1px solid #e2e5ec;
  display: flex;
  justify-content: space-between;
}

.pay-title {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  /*margin-bottom: 15px;*/
  border-bottom: 1px solid #e2e5ec;
}

.pay_detail {
  margin-left: 50px;
  font-size: 17px;
  font-weight: 600;
}

.pay_button {
  background-color: black;
  width: 70%;
  margin: 0 auto;
  margin-top: 15px;
  padding: 8px;
  border-radius: 7px;
  color: white;
  text-align: center;
  font-size: 18px;
}

.pay_button:hover {
  background-color: black;
  box-shadow: 1px 4px 0 rgba(41, 59, 99, 0.05);
  color: #00ff00;
}

.pay_button a {
  color: white;
}

.holder {
  width: 100%;
  min-height: 280px;
}

.pay_type {
  border-radius: 9px;
  padding: 15px;
  width: 70%;
  margin: 0 auto;
  background-color: rgb(235, 231, 231);
}

label {
  margin-left: 7px;
}

#info {
  width: 100%;
}

#info tr {
  border-bottom: 1px solid #e2e5ec;
}

#info td {
  padding-bottom: 10px;
  padding-top: 10px;
}

#info th {
  font-size: 17px;
  font-weight: 600;
}

.info_panel {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(7, 211, 7);
}

.instruct {
  margin-bottom: 5px;
}


/*Cart*/
.spreadcont {
  display: flex;
  justify-content: space-between;
}

.cartul {
  padding-top: 5px;
  padding-bottom: 5px;
}

.cartul li {
  font-size: 14px;
  padding: 2px
}

table {
  width: 100%;
  border-collapse: collapse;

}

tr {
  border-bottom: 1px solid grey;
}

td,
th {
  text-align: left;
  padding-top: 15px;
  vertical-align: center;

}

th {
  font-size: 15px;
  border-bottom: 1px solid rgb(187, 186, 186);
}

td {
  font-size: 15px;
}

.serve_title {
  font-weight: bold;
}

t .serve_others {
  font-size: 14px;
}

.serve_remove {

  color: red;

}

.cop_id {
  display: flex;
  width: 80px;
  text-align: center;
}

.cop_cont1:hover {
  color: green;
  border: green;

}

.cop_cont3:hover {
  background-color: red;
  border: red;
  color: #00ff00
}

.cop_cont1 {
  width: 33.3%;
  border-radius: 50%;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cop_cont2 {
  width: 33.3%;

  padding: 5px;
}

.cop_cont3 {
  width: 33.3%;
  border-radius: 50%;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amt {
  color: green;
}

/*.cart_submit {
  background-color: black;
  color: white;
  padding: 7px;
  border: none;
  float: right;
  margin-top: 15px;
  font-size: 14px;
  ;
  width: 350px;
}

.add_cart {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: green;

  margin-bottom: 7px;
}

.unavailable {
  padding: 50px;

}
*/
/*Media Queries --------------------------------------------------------------------------- */

@media screen and (max-width: 600px) {
  .form_container {
    width: 100%;
    float: none;
  }

  .upload_container {
    width: 90%;
    float: none;
    min-height: 15px;
  }

  .form_submit {
    width: 70px;
    padding: 6px;
  }

  .upload_container {
    width: 100%;
  }

  .pay_picture {
    width: 100%;
    display: none;
  }

  .pay_contain {
    width: 100%;
  }

  .pay_type {
    width: 100%;
  }

  .general_form {
    width: 100%;
  }

  .intro_cont {
    width: 100%;
  }

  .serve_title {
    font-weight: bold;
    font-size: 14.5px;
  }

  .serve_others {
    font-size: 13px;
  }

  .cart_submit {
    width: 100%;
  }

  .unavailable {
    font-size: 14px;

  }

}