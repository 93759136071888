a {
  text-decoration: none;
}
  body {
  margin: 0;
  font-family: "Roboto",
  "Helvetica",
  "Arial",
  sans-serif;
}

.header_container{
  padding-top: 15px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 12px;
    background: linear-gradient(to bottom, #f6f6f6 0, #f6f6f6 51%, #f6f6f6 85%, #f6f6f6 85%, rgba(246, 246, 246, 0) 86%);
    overflow: auto;
    display: flex;
    justify-content: space-between;
    align-items:baseline;
    font-size: 14.5px;
    position: fixed;
    width:100%;
    top:0;
    z-index: 5000;
}

.menu_list{
  display: flex;
}
.menu_list_items{
  display: flex;
  padding-left: 35px;
}

.menu_list_items li{
padding-left: 20px;
padding-right: 20px;
padding-bottom: 7px;

}
.menu_list_items li:hover{
 border-bottom: 3px solid #00ff00;
 padding-bottom: 4px;
}
.extra_acc{
  color: black;
  font-weight: bold;
  color: #046904;
}

.extra_log {
  border-right: 2px solid #046904!important;
  color: #046904;
  font-weight: bold;
}

.service_adv {
 
  padding: 2%;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.adv_title{
  font-size: 25px;
  font-weight: bold;
  text-align:  center;
 padding-top:20px;
 color: #035c03;
}

.adv_cont{
  width:19%;
  background-color: white;
  float: left;
  overflow: auto;
  margin-right: 1%;
  font-size: 16px;
    box-shadow: 1px 4px 0 rgb(0 0 0 / 5%) !important;
  
}
.adv_cont2 {
  width: 23%;
  background-color: white;
  float: left;
  overflow: auto;
    font-size: 16px;
        box-shadow: 1px 4px 0 rgb(0 0 0 / 5%) !important;
}

.subtitle{
  font-weight: bold;
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 9px;
}

.shop_now{
width:100%;
color:green;
padding-top:8px;
font-weight: 600;
display: flex;
align-items: center;


}

.leads{
  padding-top: 20px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-content: center;
  color: #035c03;
}

.contact_acc{
  background-color: #00ff00;
  color: black;
  font-weight: bold;
  font-size: 15px;
}
a{
  color: inherit !important;
}

.fortext{
  padding: 10px;
}
.ser_img{
  width:100%;
}
.header_plus{margin-top: 80px;}

/*.header_plus{
  padding-top:40px; ;
  padding-left:40px;
  padding-right: 40px;
  background-color: black;
  color: white;
 font-weight: bold;
  font-size: 15px;
margin-top: 72px;
overflow: hidden;

}*/


.to_align{

}
.plus_cont h1{
  font-size: 55px;
  padding-top: 20px;
  padding-left: 0;
  font-weight: bold;
  margin-top: 0 !important;
  color: #00ff00;

}

.extra_wlc{
  font-size: 30px;
  font-size: 25px;
  margin-top: 20px;
}

.to_align{
  display: flex;
  justify-content: center;
    padding-top: 40px;
      padding-left: 40px;
      padding-right: 40px;
   /*   background-color: black;*/
      color: white;
      font-weight: bold;
      font-size: 15px;
   
      overflow: hidden;
}
.plus_cont{
  margin:0 auto;
  max-width: 550px;

}
.new_user{
  width:130px;
  padding: 10px;
  background-color: #00ff00;
  color: black;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
}

.locate{
  display: flex;
}
.entrance_btn{
  display: flex;
  width:100%;
  max-width:300px;
  justify-content: space-between;
  margin-top: 40px;
}

.old_user {
  width: 130px;
  padding: 10px;
    border: 2px solid grey;
    display: flex;
    justify-content: center;
  align-content: center;
  border-radius: 5px;
}
.old_user:hover {
  background-color: white;
  color: black;
}

.new_user:hover {
  background-color: #92f392;
}

  .entry_container {
  border-top:3px solid #00ff00;  
  padding: 5px;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid rgb(236, 234, 234);
  box-shadow: 13px 0px rgba(82, 63, 105);
  /*border-color: #e2e5ec !important;*/
  max-height: 100px;
}

.entry_container1 {
  padding: 5px;
  position: relative;
  border-bottom: 1px solid rgb(236, 234, 234);
  box-shadow: 13px 0px rgba(82, 63, 105);
  border-color: #e2e5ec !important;
  max-height: 100px;
}

.logo_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 8px;
}

.account_header {
  display: flex;
  justify-content: center;
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.account {
  display: flex;
}
.account_category {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  color: blue;
  transition-duration: 1s;
}

.account_category:hover {
  border-bottom: 2px solid blue;
}
.account_category_text {
  color: black;
  font-size: 12.5;
  font-weight: 600;
}

.account_category_icon {
  padding-right: 3px;
}

.phone_menu li a {
  color: black;
}

.main_img {
  width: 20%;
}
.search_container {
  display: flex;
  width: 40%;
}
.mysearch_input {
  height: 38px;
  font-size: 12.5px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: rgb(53, 52, 52);
  outline: none;
  flex: 1 1 auto;
  border: 1px solid #e2e5ec;
}

.search_icon {
  color: white;
  background-color: #0046be;
  width: 58px;
  border-radius: 0px 3px 3px 0px;
  height: 38px;
  display: block;
  padding-top: 10px;
  padding-left: 13px;
}
.entry_button1 {
  border: 2px solid #303179;
  width: 95px;
  color: #303179;
  text-decoration: none;
  font-size: 16px;
  position: absolute;
  right: 70px;
  padding: 5px;
  border-radius: 25px;
  text-align: center;
  margin-top: 15px;
}

.entry_button1:hover,
.entry_button2:hover {
  background-image: linear-gradient(45deg, rgb(31, 1, 141), rgb(13, 136, 252));
  color: white;
  border: 0px solid rgb(31, 1, 141);
}

.entry_button2 {
  border: 2px solid #303179;
  width: 95px;
  color: #303179;
  text-decoration: none;
  font-size: 16px;
  position: absolute;
  right: 250px;
  padding: 5px;
  border-radius: 25px;
  text-align: center;
  margin-top: 15px;
}

.contact {
  display: block;
  width: 200px;
}
.contact_dropdown {
  background-color: rgb(250, 246, 246);
  padding: 5px;
  padding-top: 10px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  font-size: 12.5px;
  background-color: white;
  box-shadow: 1px 4px 0 rgb(0 0 0 / 5%) !important;
  color: #0046be;
  transition: 2s;
}

.details {
  display: flex;
  align-content: center;
  padding: 3px 0px 3px 0px;
}

.display {
  display: none;
}
.home_menu {
  padding-top: 15px;
  display: flex;
}

.category {
  color: #032d75;
  display: flex;
  padding: 0px 20px 0px 20px;
  padding-bottom: 0px;
  /*border-right: 2px solid #0046be;*/
  font-size: 12.5px;
  transition-duration: 1s;
  height: 31px;
}
.contact_category {
  color: #032d75;
  display: flex;
  padding: 0px 20px 0px 20px;
  padding-bottom: 0px;
  /*border-right: 2px solid #0046be;*/
  font-size: 12.5px;
  transition-duration: 1s;
  height: 31px;
  width: 150px;
}
.category:hover {
  color: white;
  cursor: pointer;
  background-color: blue;
}
.contact_category:hover {
  color: white;
  cursor: pointer;
  background-color: blue;
}
.category_text {
  padding-top: 3px;
}
.category_icon {
  padding-bottom: 0;
  padding-right: 5px;
}
.category_icon:hover {
  color: white !important;
}

.phone_menu {
  transition: 1s;
}
.subli {
  background-color: black;
}
.image {
  margin-bottom: 0;
}

.side_img {
  height: 700px;
  border-radius: 50% 0% 50%;
  float: right;
}

.foot {
  background-color: black;
  padding-bottom: 10px;
  color:white;
  font-family: "Roboto",
  "Helvetica",
  "Arial",
  sans-serif;
 
}

.foot_main{
    padding: 50px;
    display:block;
    overflow: auto;
}

.additional_info{
  background-color: black;
  border-top: 1px solid #00ff00 ;
  padding-top: 10px;
    padding-bottom: 10px;
      padding-left: 90px;
      padding-right: 90px;
      color: white;justify-content: space-between;
      display: flex;
      font-size: 14px;

}
.w_redirect{
width: 40px;
border-radius: 50%;
position: fixed;
right: 20px;
top: 400px;
z-index: 100;
 
}

.w_redirect:hover {
  background-color: white;
}

.w_redirect img{
  width: 100%;
  border-radius: 50%;
  }

.icon_holder{
  display: flex;
  width:250px;
}

.foot_icon{
  width:25%;
}

.foot h3{
  font-weight:bolder;
  margin-top:0;
}
.foot p {
  font-size:16px;
}

.foot_content{
  padding-left: 40px;
    padding-right: 40px;
    width:25%;
    float: left;
      margin-bottom: 25px;
}
.foot_content2 {
  padding-left: 40px;
  padding-right: 40px;
  width: 20%;
  float: left;
    margin-bottom: 25px;
}

.foot_content3 {
  padding-left: 40px;
  padding-right: 40px;
  width: 35%;
  float: left;
  margin-bottom: 15px;

}
.newsletter{
  font-weight: bold;
  font-size: 19px !important;
  text-align: center;
}

.newsletter_info {
  padding-top: 10px;
  font-size: 14px !important;
  text-align: center;
  margin-bottom: 20px;
}


.email_c{
  width: 100%;
  padding: 8px;
  padding-left: 12px;
  margin-bottom: 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  border: 1px groove #babbbe;
  color: rgb(36, 35, 35);
  font-size: 13px;
  background-color: white;
  font-weight: 500;
  max-width: 400px;
}

.n_submit{
  background-color: #00ff00;
  color: white;
  width:100%;
  color: black;
  border: none;
  padding-left: 20px;
    padding-right: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        border-radius: 7px;
        font-size: 15px;
        font-weight: bold;
        max-width: 400px;
}
.foot_title{
  font-weight: bold;
  padding-bottom: 15px !important;
}
.foot_content2 li {padding:5px;}
.plus{
  font-size: 15px;
}
.foot_menu {
  display: flex;
  justify-content: center;
  color: white;
}
.foot_menu li {
  float: left;
  margin-left: 30px;
  border-right: 3px solid rgb(169, 197, 240);
  list-style-type: none;
  padding-right: 8px;
}

.sub_foot1 {
  display: flex;
  justify-content: center;
}

.sub_foot2 {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: rgb(228, 234, 240);
}

.links {
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.hide {
  color: rgb(31, 1, 141);
}

.twiter_logo {
  height: 25px;
  border-radius: 45%;
  margin-right: 30px;
}

.facebook_logo {
  height: 25px;
  border-radius: 45%;
  margin-right: 30px;
}

.instagram_logo {
  height: 25px;
  border-radius: 45%;
}

.linkedin_logo {
  height: 25px;
  border-radius: 45%;
  margin-right: 30px;
}





/*profile header styles */

.header_logo {
  height: 40px;
}
.profile_container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(31, 1, 141);
}

.notify {
  color: BLUE;
  padding: 10px;
  border-radius: 7px;
  float: right;
}

.mini_details {
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding: 2px;
}
.mini_details:active {
  color: cornsilk;
}
.mini_name {
  padding-left: 10px;
  padding-top: 10px;
  font-size: 11px;
}

.mini_arrow {
  padding-left: 15px;
  padding-top: 11px;
}

.mini_details:active {
  background-color: rgb(13, 128, 236);
  padding: 2px;
  border-radius: 3px;
}
.phone_search {
  display: flex;
  width: 90%;
}

.phone_input {
  width: 85%;
  border: 1px solid rgb(168, 166, 166);
  color: grey;
  font-weight: 600;
  flex: 1 1 auto;
}

.phone_input:focus {
  outline: 1px solid blue;
}

.phone_icon {
  background-color: #0046be;
  color: white;
  padding-left: 5px;
  display: flex;
  align-items: center;
  width: 40px;
  height: 35px;
}

.phone_menu li:hover > a {
  color: white;
}

.profile_category:hover {
  color: white;
  background-color: blue;
}
.profile_category:hover .account_category_text {
  color: white;
}
.profile_category {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  color: blue;
  transition-duration: 1s;
}


.product{
  width:20%;
  background-color: white;
  padding: 1%;
  border-radius: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #00ff00;
  float: left;
}

.shop_cont{
  margin-top: 100px;
  font-size: 15px;
}


.product:active{
border:1px solid #00ff00;
}


.productinfo{

font-size: 14px;

}

.productimg {
 max-width: 100%;
}
.imgcont{
display: flex;
width:100%;
justify-content: center;
  height: 150px;
  
}
/*--------------------------Dashboard-----------------*/
.b_img{
  width:400px;
  height: 300px;
}

/************************************************/
.shop_menuhead{
display: flex;
align-items: center;
color: #035c03;
cursor: pointer;
}

.shop_menuhead li {color: #028e02;}


.item_menu li {
  padding: 7px;
}

.item_menu {
  margin-left: 30px;
  width: 200px;
  box-shadow: 2px 0px 0 rgb(0 0 0 / 5%) !important;
}

.item_menu li:hover {
 border-bottom: 1px solid #babbbe;
 background-color: #8df78d;
}

.shop_menuhead li:hover {
  background-color: white;
}
/*.......................................................................................................................*/

@media screen and (min-width: 1200px) {
  .navbar1 {
    display: none;
  }

  .navbar2 {
    display: none;
  }

  #display {
    display: none;
  }
  .phone_menu {
    display: none;
  }

  .foot_content {
    width: 25% !important;
  }
  
  .foot_content2 {
    width: 20%  !important;
  }
  
  .foot_content3 {
    width: 35%  !important;
  }

  .header_container {
    padding-left: 70px  !important;
    padding-right: 70px  !important;
  }


}

@media screen and (max-width: 767px) {
  .product{
width: 100%;
  }
  .mysearch_input {
    display: none;
  }

  .search_icon {
    display: none;
  }
  .account {
    display: none;
  }
  .entry_container {
    height: auto;
  }

  .account_header {
    display: none;
  }
  .main_img {
    width: 200px;
  }
  .entry_button2 {
    display: none;
  }

  .entry_button1 {
    display: none;
  }

  .home_menu {
    display: none;
  }

  .image {
    display: flex;
    justify-content: center;
  }

  .navbar1 {
    position: relative;
    left: 10px;
    font-size: 20px;
    color: black;
    cursor: pointer;
    padding-top: 7px;
  }

  .navbar2 {
    display: none;
  }

  .phone_menu {
    display: none;
  }

  .menu_display {
    display: block;
    padding-bottom: 10px;
    border-bottom: 7px solid #00ff00;
    color: black;
    max-height: 100%;
    font-size: 15px;
    position: fixed;
    background-color: white;
    width: 100%;
    top: 78px;
      z-index: 100;
      
  }

  .menu_display li:hover {
    color: white;
  }
  .service_menu {
    display: none;
  }
  .menu_show {
    display: none;
  }
  .menu_display ul {
    list-style-type: none;
  }

  .menu_display li {
    padding: 12px;
    padding-left: 20px;
    list-style-type: none;
    transition: 1s;
  }


  .menu_display ul {
    padding-left:0 !important;
  }

  .menu_display li:hover {
    color: black;
    background-color:#d3f8d3;
    
  }

  #service_display:hover {
    cursor: pointer;
  }

  .dropdown_menu {
    display: block;
  }

  /*profile media */
  .header_logo {
    height: 35px;
  }
  .mini_name {
    display: none;
  }


  .foot_content{
    width:100%;
    padding-left: 0px;
    padding-right: 0px;
    
  }
  .foot_content2 {
    width:100%;
    padding-left: 0px;
    padding-right: 0px;
  
  }
  .foot_content3 {
width:100%;
padding-left: 0px;
padding-right: 0px;
  }
.additional_info{
  display:block;
  width:100%;
  padding-left:40px;
  padding-right: 40px;
}

.icon_holder{
  width:100%;
  max-width: 300px;
}


  .newsletter{
    float:left;
    width:100%;
    border-top: 1px solid #00ff00;
    padding-top: 25px;
    text-align: left;
    
  }

  .newsletter_info {
    width: 100%;
    text-align: left;
  }

  .menu_list{
    display: none;
  }
  .header_container{
    padding-top: 20px;
    padding-bottom: 20px;
  }

    .header_plus{
    margin-top: 80px;
    
  }
.adv_title{
  font-size: 22px;
}
  .adv_cont{
    width:90%;
    
        margin: 0 auto;
        margin-left: 5%;;
    margin-bottom:30px;
  }

    .adv_cont2 {
      width: 90%;
      margin: 0 auto;
      margin-left: 5%;
      margin-bottom: 30px;
    }

    .header_container{
      padding-left: 25px;
      padding-right: 25px;
    }

    .extra_wlc{
      font-size: 18px;
    }

    .header_plus h1{
     /* font-size: 35px;*/
      padding-top: 20px;
    }
    .mainlogo{
      height:50px !important;
    }

 .item_menu {
     
          width: 100%;
          margin-left:10px;
        }

        .hide_item{
          display: none;
        }
}

.head_cover {
  width: 100%;
}

.head_import {
  width: 70%;
  float: left;
}

@media screen and (min-width: 768px) {
  .navbar1 {
    display: none;
  }
  .mysearch_input {
    width: 250px;
  }
  .navbar1 {
    display: none;
  }
  .navbar2 {
    display: none;
  }

  #display {
    display: none;
  }
  .phone_menu {
    display: none;
  }
  .entry_button1 {
    position: absolute;
    right: 50px;
  }

  .entry_button2 {
    position: absolute;
    right: 170px;
  }

  .foot_content{
    width:40%;
  }
  .foot_content2 {
    width: 40%;
  }
  .foot_content3 {
    width: 58%;
   
  }

  .newsletter{
    text-align: left;
  }
  .newsletter_info {
    text-align: left;
  }
  .header_container {
    padding-left: 10px;
    padding-right: 10px;
  }
  
}

@media screen and (max-width: 1200px) {
  .search_container {
    display: flex;
    width: auto;
  }

 
  
}
